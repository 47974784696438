var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "inspectPointImport-wrapper" },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("v-button", {
            attrs: { text: "返回" },
            on: { click: _vm.previous },
          }),
        ],
        1
      ),
      _c(
        "import-template",
        { attrs: { uploadUrl: _vm.uploadUrl, downloadUrl: _vm.downloadUrl } },
        [
          _c("div", { attrs: { slot: "remark" }, slot: "remark" }, [
            _c("p", [
              _vm._v("1、模板中红色字体部分为必填项，灰色部分为非必填项；"),
            ]),
            _c("p", [_vm._v("2、最大支持10MB的excel文件，超过请拆分导入。")]),
            _c("div", [
              _c(
                "label",
                {
                  staticStyle: {
                    position: "absolute",
                    left: "90px",
                    "font-weight": "700",
                  },
                },
                [_vm._v("字段说明：")]
              ),
              _c("p", [
                _vm._v("1、所属项目：必填；必须是项目信息表中已有的项目名称；"),
              ]),
              _c("p", [_vm._v("2、组团：非必填，项目中的”苑“信息；")]),
              _c("p", [_vm._v("3、巡查类型：必填，只能填写：项目巡查；")]),
              _c("p", [
                _vm._v("4、巡查一级分类：必填，巡查分类中已有的一级分类内容；"),
              ]),
              _c("p", [
                _vm._v("5、巡查二级分类：必填，巡查分类中已有的二级分类内容；"),
              ]),
              _c("p", [_vm._v("6、巡查频次：必填，0~99；")]),
              _c("p", [
                _vm._v("7、巡查频次时间：必填，单选：日、月、周、季；"),
              ]),
              _c("p", [_vm._v("8、巡查间隔：必填；")]),
              _c("p", [_vm._v("9、巡查间隔时间：必填；单选：日、小时")]),
              _c("p", [_vm._v("10、巡查方式：必填，单选:蓝牙、二维码；")]),
              _c("p", [_vm._v("11、蓝牙编号：巡查方式为蓝牙时，必填；")]),
              _c("p", [_vm._v("12、规则生效时间：必填，格式:YY-MM-DD；")]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }