<!--
 * @Description: 巡查管理--巡查点管理--导入页面
 * @Author: 小广
 * @Date: 2019-05-29 18:33:57
 * @LastEditors: xfj
 * @LastEditTime: 2020-03-10 10:15:33
 -->
<template>
  <div class="inspectPointImport-wrapper">
    <div class="header">
      <v-button text="返回" @click="previous"></v-button>
    </div>
    <import-template :uploadUrl="uploadUrl" :downloadUrl="downloadUrl">
      <div slot="remark">
        <p>1、模板中红色字体部分为必填项，灰色部分为非必填项；</p>
        <p>2、最大支持10MB的excel文件，超过请拆分导入。</p>
        <div>
          <label style="position: absolute;left: 90px;font-weight: 700;">字段说明：</label>
          <p>1、所属项目：必填；必须是项目信息表中已有的项目名称；</p>
          <p>2、组团：非必填，项目中的”苑“信息；</p>
          <p>3、巡查类型：必填，只能填写：项目巡查；</p>
          <p>4、巡查一级分类：必填，巡查分类中已有的一级分类内容；</p>
          <p>5、巡查二级分类：必填，巡查分类中已有的二级分类内容；</p>
          <p>6、巡查频次：必填，0~99；</p>
          <p>7、巡查频次时间：必填，单选：日、月、周、季；</p>
          <p>8、巡查间隔：必填；</p>
          <p>9、巡查间隔时间：必填；单选：日、小时</p>
          <p>10、巡查方式：必填，单选:蓝牙、二维码；</p>
          <p>11、蓝牙编号：巡查方式为蓝牙时，必填；</p>
          <p>12、规则生效时间：必填，格式:YY-MM-DD；</p>
       </div>
      </div>
    </import-template>
  </div>
</template>
<script>
import { ImportTemplate } from 'components/bussiness/index'
import { importMetersURL } from './api'
export default {
  components: {
    ImportTemplate
  },
  data () {
    return {
      uploadUrl: importMetersURL,
      downloadUrl: '/static/excel/巡查点批量导入模板.xlsx'
    }
  },
  created () {
    this.$setBreadcrumb('导入巡查点')
  },
  methods: {
    previous () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
  .inspectPointImport-wrapper {
    text-align: left;
    background: #fff;
  }
  .header {
    position: absolute;
    top: 20px;
    right: 20px;
    :not(:last-child) {
      margin-right: 8px;
    }
  }
</style>
